<template>
  <v-col
    cols="12"
    md="12"
  >
    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Продукты</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество {{ productsList.length }}</h5>
        </div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="productsList"
          :loading="loading"
        >
          <template v-slot:[`item.price`]="{ item }">
            <span :class="{ 'with-line': item.new_price }">
              {{ item.price }}
            </span>
          </template>

          <template v-slot:[`item.new_price`]="{ item }">
            <span>
              {{ item.new_price || '-' }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </v-col>
</template>

<script>
import ProductsService from '@/app/Services/ProductsService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'

export default {
  name: 'ProductsDashboard',

  mixins: [TableDialogMixin],

  data() {
    return {
      productsList: [],
      headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Имя',
          value: 'name',
        },
        {
          sortable: true,
          text: 'Цена',
          value: 'price',
        },
        {
          sortable: true,
          text: 'Новая цена',
          value: 'new_price',
        },
      ],
    }
  },
  
  computed: {},

  async created() {
    this.loading = true
    await this.getProducts()
    this.loading = false
  },

  methods: {
    async getProducts() {
      const response = await ProductsService.getProducts()

      if (response.status) {
        this.productsList = response.data.data

        this.$store.dispatch('products/setProductsData', response.data.data)
      }
    },
  },
}
</script>

<style
  lang="sass"
  scoped
>
.with-line
  text-decoration: line-through
</style>
